html {
    transform-origin: 0 0;
}

@media (max-width: 1919px) {
    html { transform: scale(0.95); }
}

@media (max-width: 1900px) {
    html { transform: scale(0.925); }
}

@media (max-width: 1850px) {
    html { transform: scale(0.9); }
}

@media (max-width: 1750px) {
    html { transform: scale(0.85); }
}

@media (max-width: 1650px) {
    html { transform: scale(0.8); }
}

@media (max-width: 1550px) {
    html { transform: scale(0.75); }
}

@media (max-width: 1450px) {
    html { transform: scale(0.7); }
}

@media (max-width: 1350px) {
    html { transform: scale(0.65); }
}

@media (max-width: 1275px) {
    html { transform: scale(0.6); }
}

@media (max-width: 1175px) {
    html { transform: scale(0.55); }
}

@media (max-width: 1075px) {
    html { transform: scale(0.5); }
}

@media (max-width: 975px) {
    html { transform: scale(0.45); }
}

@media (max-width: 875px) {
    html { transform: scale(0.4); }
}

@media (max-width: 825px) {
    html { transform: scale(0.375); }
}

@media (max-width: 775px) {
    html { transform: scale(0.35); }
}

@media (max-width: 725px) {
    html { transform: scale(0.325); }
}

@media (max-width: 675px) {
    html { transform: scale(0.3); }
}

@media (max-width: 625px) {
    html { transform: scale(0.275); }
}

@media (max-width: 575px) {
    html { transform: scale(0.25); }
}

@media (max-width: 525px) {
    html { transform: scale(0.225); }
}

@media (max-width: 475px) {
    html { transform: scale(0.2); }
}

@media (max-width: 425px) {
    html { transform: scale(0.175); }
}

@media (max-width: 375px) {
    html { transform: scale(0.15); }
}

@media (max-width: 325px) {
    html { transform: scale(0.125); }
}

@media (max-width: 275px) {
    html { transform: scale(0.1); }
}