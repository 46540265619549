
.arrow-left {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 50px solid white;
    margin-top: 16px;
    margin-right: 30px;
  }
  
.arrow-right {
    width: 0; 
    height: 0; 
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 50px solid white;
    margin-top: 16px;
    margin-left: 30px;
}

.text {
    width: 120px;
}